import { SET_LOADING_TRUE, SET_LOADING_FALSE, SET_PROGRAM_DETAILS, GET_SIMPLE_LOOKUPS_SUCCESS, SET_ALERT_VISIBILITY } from './constants';

const INITIAL_STATE = {
  // Your initial state here
  programDetails: null,
  showAlert: false,
  alertMessage: '',
  alertState: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_SIMPLE_LOOKUPS_SUCCESS:
      return {
        ...state,
        simpleLookups: action?.payload?.simpleLookups,
      };
    case SET_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: action.payload,
      };
    case SET_ALERT_VISIBILITY:
      return {
        ...state,
        showAlert: action.payload.showAlert,
        alertMessage: action.payload.alertMessage,
        alertState: action.payload.alertState,
      }
    default:
      return state;
  }
};
