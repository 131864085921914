export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_PUBLISH_SUCCESS = 'GET_CUSTOM_FIELDS_PUBLISH_SUCCESS';
export const GET_CUSTOM_FIELDS_FAILURE = 'GET_CUSTOM_FIELDS_FAILURE';
export const GET_CLIENT_RESET = 'GET_CLIENT_RESET';
export const GET_CLIENT_START = 'GET_PLAN_START';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const CLOSE_DELETE_USER_TOAST = 'CLOSE_DELETE_USER_TOAST';
export const UPDATE_USER_ACCESS_STATUS_START = 'UPDATE_USER_ACCESS_STATUS_START';
export const UPDATE_USER_ACCESS_STATUS_SUCCESS = 'UPDATE_USER_ACCESS_STATUS_SUCCESS';
export const UPDATE_USER_ACCESS_STATUS_FAILURE = 'UPDATE_USER_ACCESS_STATUS_FAILURE';
export const CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST = 'CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST';
export const GET_KAHUA_FIELD_LIST_SUCCESS = 'GET_KAHUA_FIELD_LIST_SUCCESS';
export const GET_KAHUA_FIELD_LIST_FAILURE = 'GET_KAHUA_FIELD_LIST_FAILURE';
export const GET_KAHUA_FIELD_LIST_RESET = 'GET_KAHUA_FIELD_LIST_RESET';
export const GET_KAHUA_FIELD_LIST_START = 'GET_KAHUA_FIELD_LIST_START';
export const SET_SECTION_HEADER = 'SET_SECTION_HEADER';
export const UPDATE_SECTION_HEADER_SUCCESS = 'UPDATE_SECTION_HEADER_SUCCESS';
export const UPDATE_SECTION_HEADER_FAILURE = 'UPDATE_SECTION_HEADER_FAILURE';
export const ADD_SECTION_HEADER_SUCCESS = 'ADD_SECTION_HEADER_SUCCESS';
export const ADD_SECTION_HEADER_FAILURE = 'ADD_SECTION_HEADER_FAILURE';

export const SAVE_DROPDOWN_LIST_START = 'SAVE_DROPDOWN_LIST_START';
export const SAVE_DROPDOWN_LIST_END = 'SAVE_DROPDOWN_LIST_END';
export const SET_CDF_REQUIRED = 'CUSTOM_FIELDS_MANAGEMENT/SET_CDF_REQUIRED';
export const MODULE = 'cbre-pjm-custom-fields-management';
export const FEATURE_NAMES = {
  CUSTOM_FIELD: 'CustomFieldsMgmt',
  MANAGE_COLUMN: 'ManageColumns',
  PROGRAM_MANAGEMENT: 'ProgramManagement',
};
export const FEATURE_APPS = {
  CUSTOM_FIELD: ['PJM02', 'PJM13'],
  MANAGE_COLUMN: ['PJM02', 'PJM05', 'PJM13', 'PJM16', 'PJM19'],
  PROGRAM_MANAGEMENT: ['PJM09', 'PJM10'],
};
