/* eslint-disable */
import flatten from 'flat';
import ApprovalsGroup from 'modules/cbre-pjm-approvals-group/locales/en_US.json';
import enMessages from '../locales/en_US.json';
import estimatorSpacePlan from '../../modules/cbre-pjm-estimator-space-plan/locales/en_US.json';
import newclientlanguage from '../../modules/cbre-pjm-newclient/locales/en_US.json';
import requestaccesslanguage from '../../modules/cbre-pjm-requestaccess/locales/en_US.json';
import capitalPlanDetailsLanguage from '../../modules/cbre-pjm-capital-plan-details/locales/en_US.json';
import capitalPlanCriteriaSettingsLanguage from '../../modules/cbre-pjm-capital-plan-criteriasetting/locales/en_US.json';
import capitalPlanPlansListLanguage from '../../modules/cbre-pjm-capital-plan-plans-list/locales/en_US.json';
import addPlanLanguage from '../../modules/cbre-pjm-capital-plan-addplan/locales/en_US.json';
import capitalPlansLanguage from '../../modules/cbre-pjm-cp-clients/locales/en_US.json';
import ProgramManagementLanguage from '../../modules/cbre-pjm-pm-clients-list/locales/en_US.json';
import estimatorLanguage from '../../modules/cbre-pjm-estimator-clients/locales/en_US.json';
import capitalPlanNewProjectLanguage from '../../modules/cbre-pjm-capital-plan-new-project/locales/en_US.json';
import capitalPlanNewProjectExportToKahuaLanguage from '../../modules/cbre-pjm-capital-planner-export-to-kahua/locales/en_US.json';
import projectTrackerNewProjectLanguage from '../../modules/cbre-pjm-project-tracker-new-project/locales/en_US.json';
import projectTrackerProjectsListLanguage from '../../modules/cbre-pjm-project-tracker-projects-list/locales/en_US.json';
import jAProjectsListLanguage from '../../modules/cbre-pjm-ja-projects-list/locales/en_US.json';
import cppProjectsListLanguage from '../../modules/cbre-pjm-cpp-projects-list/locales/en_US.json';
import cppBasicProjectInfoLanguage from '../../modules/cbre-pjm-cpp-basic-project-info/locales/en_US.json';
import reactandSubmitLanguage from '../../modules/cbre-pjm-pds-review/locales/en_US.json';
import jABasicProjectInfoLanguage from '../../modules/cbre-pjm-pds-basic-project-info/locales/en_US.json';
import costPlanInfoLanguage from '../../modules/cbre-pjm-pds-cost-plan/locales/en_US.json';
import pdsProjectOverviewLanguage from '../../modules/cbre-pjm-pds-project-overview/locales/en_US.json';
import pdsProjectRisksLanguage from '../../modules/cbre-pjm-pds-project-risks/locales/en_US.json';
import pdsApprovalsLanguage from '../../modules/cbre-pjm-pds-approvals/locales/en_US.json';
import pdsClientApprovalsLanguage from '../../modules/cbre-pjm-pds-client-approvals/locales/en_US.json';
import pdsMyActionsLanguage from '../../modules/cbre-pjm-pds-my-actions/locales/en_US.json';
import pdsFormRoot from '../../modules/cbre-pjm-pds-form-root/locales/en_US.json';
import capitalPlanImportLanguage from '../../modules/cbre-pjm-capital-plan-import/locales/en_US.json';
import ApprovalsLanguage from '../../modules/cbre-pjm-approvals/locales/en_US.json';
import capitalPlanDeletePlanLanguage from '../../modules/cbre-pjm-capital-plan-deleteplan/locales/en_US.json';
import capitalPlanDeleteProgramLanguage from '../../modules/cbre-pjm-capital-plan-deleteprogram/locales/en_US.json';
import capitalPlanDeleteProjectLanguage from '../../modules/cbre-pjm-capital-plan-deleteproject/locales/en_US.json';
import emailAuthentication from '../../modules/cbre-pjm-email-authentication/locales/en_US.json';
import projectTrackerNewProjectKahuaLanguage from '../../modules/cbre-pjm-project-tracker-new-project-kahua/locales/en_US.json';
import projectTrackerAddUserLanguage from '../../modules/cbre-pjm-project-tracker-new-project-add-user/locales/en_US.json';
import userManagementSearchAddUserLanguage from '../../modules/cbre-pjm-user-management-search-add-user/locales/en_US.json';
import createProgramLanguage from '../../modules/cbre-pjm-create-program/locales/en_US.json';
import HierarchyAccessSearchAddUserTeamLanguage from '../../modules/cbre-pjm-hierarchy-access-search-add-user-team/locales/en_US.json';
import ManageTeamsLanguage from '../../modules/cbre-pjm-manage-teams/locales/en_US.json';
import ManageTeamUsersLanguage from '../../modules/cbre-pjm-manage-team-users/locales/en_US.json';
import userManagementDetailsLanguage from '../../modules/cbre-pjm-user-management/locales/en_US.json';
import customFieldsManagementDetailsLanguage from '../../modules/cbre-pjm-custom-fields-management/locales/en_US.json';
import fusionVendorProjectsLanguage from '../../modules/cbre-pjm-fusion-vendors-projects/locales/en_US.json';
import estimatorNewProjectLanguage from '../../modules/cbre-pjm-estimator-new-project/locales/en_US.json';
import estimatorSpacePlanLanguage from '../../modules/cbre-pjm-estimator-space-plan/locales/en_US.json';
import estimatorProjectLanguage from '../../modules/cbre-pjm-estimator-projects-list/locales/en_US.json';
import cppFormRoot from '../../modules/cbre-pjm-cpp-form-root/locales/en_US.json';
import adminDataImport from '../../modules/cbre-pjm-admin-data-import/locales/en_US.json';
import documentLanguage from '../../modules/cbre-pjm-document/locales/en_US.json';
import dataMigrationLanguage from '../../modules/cbre-pjm-kahua-data-migration/locales/en_US.json';
import newDataMigrationLanguage from '../../modules/cbre-pjm-kahua-new-migration/locales/en_US.json';
import capitalPlanMoveProjectLanguage from '../../modules/cbre-pjm-capital-plan-moveproject/locales/en_US.json';
import capitalPlanMoveProgramLanguage from '../../modules/cbre-pjm-capital-plan-moveprogram/locales/en_US.json';
import clientDataFields from '../../modules/cbre-pjm-capital-plan-client-data-fields/locales/en_US.json';
import programManagementProjectLanguage from '../../modules/cbre-pjm-pm-projects-list/locales/en_US.json';
import programManagementProgramsList from '../../modules/cbre-pjm-pm-program-list/locales/en_US.json';
import programManagementProjectsList from '../../modules/cbre-pjm-pm-projects-list/locales/en_US.json';
import capitalPlanFundingRequest from '../../modules/cbre-pjm-capital-plan-funding-request/locales/en_US.json';
import projectTagging from '../../modules/cbre-pjm-project-tagging/locales/en_US.json';
import approvalStatusListLanguage from '../../modules/cbre-pjm-approvals-status-list/locales/en_US.json';
import forecast from '../../modules/cbre-pjm-capital-plan-forecasting/locales/en_US.json';
import forecastPL from '../../modules/principal-lite/cbre-pjm-principal-lite-project-forecast/locales/en_US.json';
import capitalPlanManageReference from '../../modules/cbre-pjm-capital-plan-manage-references/locales/en_US.json';
import manageLookupTypeListLanguage from '../../modules/cbre-pjm-manage-list/locales/en_US.json';
import approvalConfigAddGroup from '../../modules/cbre-pjm-approval-configuration-add-group/locales/en_US.json';
import approvalConfig from '../../modules/cbre-pjm-approval-configuration/locales/en_US.json';
import adminRoot from '../../modules/cbre-pjm-admin-root-details/locales/en_US.json';
import checkListRoot from '../../modules/principal-lite/cbre-pjm-principal-lite-project-checklist/locales/en_US.json';
import milestonesRoot from '../../modules/principal-lite/cbre-pjm-principal-lite-project-milestones/locales/en_US.json';
import executiveSummaryLanguage from '../../modules/cbre-pjm-pds-executive-summary/locales/en_US.json';
import principalLiteReports from '../../modules/principal-lite/cbre-pjm-principal-lite-reports/locales/en_US.json';
import GridListLanguage from '../../modules/cbre-pjm-grid/locales/en_US.json';
import PrincipalLiteRootLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-root/locales/en_US.json';
import PrincipalLiteProjectRootLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-root/locales/en_US.json';
import PrincipalLiteProjectBasicInformationLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-basic-information-root/locales/en_US.json';
import PrincipalLiteProjectDocumentLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-documents/locales/en_US.json';
import principalLiteProjectCostPlan from '../../modules/principal-lite/cbre-pjm-principal-lite-project-cost-plan/locales/en_US.json';
import principalLiteNewProject from '../../modules/principal-lite/cbre-pjm-principal-lite-new-project/locales/en_US.json';
import PrincipalLiteMoveProject from '../../modules/principal-lite/cbre-pjm-principal-lite-moveproject/locales/en_US.json';
import principalLiteClientBilling from '../../modules/principal-lite/cbre-pjm-principal-lite-client-billing/locales/en_US.json';
import ProjectOverviewCost from '../../modules/cbre-pjm-project-cost-overview/locales/en_US.json';
import approvalHistory from '../../modules/common/cbre-pjm-approval-history/locales/en_US.json';
import ProjectDashboard from '../../modules/principal-lite/cbre-pjm-principal-lite-project-dashboard/locales/en_US.json';
import DocumentRepository from '../../modules/common/cbre-pjm-document-repository/locales/en_US.json';
import principalLiteRecentProjects from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-content/locales/en_US.json';
import principalLiteCppDocument from '../../modules/principal-lite/cbre-pjm-principal-lite-cpp-document/locales/en_US.json';
import principalLiteActions from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-actions/locales/en_US.json';
import DocumentsDownloadLanguage from '../../modules/cbre-pjm-download/locales/en_US.json';
import principalLiteDashboardGraphs from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-graphs/locales/en_US.json';
import DashboardFilters from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-filters/locales/en_US.json';
import PrincipalLiteFinancialOverview from '../../modules/principal-lite/cbre-pjm-principal-lite-financial-overview/locales/en_US.json';
import PrincipalLiteAddUserLanguage from '../../modules/principal-lite/cbre-pjm-principle-lite-add-user/locales/en_US.json';
import principalLiteWidgetSummary from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-summary/locales/en_US.json';
import PrincipalLiteOrderBook from '../../modules/principal-lite/cbre-pjm-principal-lite-order-book/locales/en_US.json';
import PrincipalLitePipeline from '../../modules/principal-lite/cbre-pjm-principal-lite-pipeline/locales/en_US.json';
import EmailTemplates from '../../modules/cbre-pjm-email-templates/locales/en_US.json';
import PrincipalLiteProjectBudgetManagement from '../../modules/principal-lite/cbre-pjm-principal-lite-budget-management/locales/en_US.json';
import ApprovalActions from '../../modules/cbre-pjm-approval-actions/locales/en_US.json';
import MyBuyRequestsPage from '../../modules/principal-lite/cbre-pjm-principal-lite-mybuy-requests/locales/en_US.json';
import ProjectTemplateDrawer from '../../modules/cbre-pjm-project-template-drawer/locales/en_US.json';
import ProgramCreationDrawer from '../../modules/cbre-pjm-create-new-program-drawer/locales/en_US.json';
import MyProjectsProgramsPage from '../../modules/cbre-pjm-principal-lite-programs/locales/en_US.json';
import manageUserLanguage from '../../modules/cbre-pjm-manage-user/locales/en_US.json';
import manageContactLanguage from '../../modules/cbre-pjm-manage-contact/locales/en_US.json';
import valueCreationLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-value-creation/locales/en_US.json';

const enLangMessages = flatten({
  ...enMessages,
  ...{ 'cbre-pjm-newclient': newclientlanguage },
  ...{ 'cbre-pjm-requestaccess': requestaccesslanguage },
  ...{ 'cbre-pjm-capital-plan-details': capitalPlanDetailsLanguage },
  ...{ 'cbre-pjm-capital-plan-criteriasetting': capitalPlanCriteriaSettingsLanguage },
  ...{ 'cbre-pjm-capital-plan-plans-list': capitalPlanPlansListLanguage },
  ...{ 'cbre-pjm-capital-plan-addplan': addPlanLanguage },
  ...{ 'cbre-pjm-cp-clients': capitalPlansLanguage },
  ...{ 'cbre-pjm-pm-clients-list': ProgramManagementLanguage },
  ...{ 'cbre-pjm-capital-planner-export-to-kahua': capitalPlanNewProjectExportToKahuaLanguage },
  ...{ 'cbre-pjm-estimator-clients': estimatorLanguage },
  ...{ 'cbre-pjm-estimator-new-project': estimatorNewProjectLanguage },
  ...{ 'cbre-pjm-estimator-space-plan': estimatorSpacePlanLanguage },
  ...{ 'cbre-pjm-capital-plan-new-project': capitalPlanNewProjectLanguage },
  ...{ 'cbre-pjm-capital-plan-import': capitalPlanImportLanguage },
  ...{ 'cbre-pjm-project-tracker-new-project': projectTrackerNewProjectLanguage },
  ...{ 'cbre-pjm-project-tracker-projects-list': projectTrackerProjectsListLanguage },
  ...{ 'cbre-pjm-ja-projects-list': jAProjectsListLanguage },
  ...{ 'cbre-pjm-cpp-projects-list': cppProjectsListLanguage },
  ...{ 'cbre-pjm-cpp-basic-project-info': cppBasicProjectInfoLanguage },
  ...{ 'cbre-pjm-pds-basic-project-info': jABasicProjectInfoLanguage },
  ...{ 'cbre-pjm-pds-cost-plan': costPlanInfoLanguage },
  ...{ 'cbre-pjm-pds-review': reactandSubmitLanguage },
  ...{ 'cbre-pjm-pds-project-overview': pdsProjectOverviewLanguage },
  ...{ 'cbre-pjm-pds-project-risks': pdsProjectRisksLanguage },
  ...{ 'cbre-pjm-pds-approvals': pdsApprovalsLanguage },
  ...{ 'cbre-pjm-pds-client-approvals': pdsClientApprovalsLanguage },
  ...{ 'cbre-pjm-pds-my-actions': pdsMyActionsLanguage },
  ...{ 'cbre-pjm-pds-form-root': pdsFormRoot },
  ...{ 'cbre-pjm-approvals': ApprovalsLanguage },
  ...{ 'cbre-pjm-capital-plan-deleteplan': capitalPlanDeletePlanLanguage },
  ...{ 'cbre-pjm-capital-plan-deleteprogram': capitalPlanDeleteProgramLanguage },
  ...{ 'cbre-pjm-capital-plan-deleteproject': capitalPlanDeleteProjectLanguage },
  ...{ 'cbre-pjm-email-authentication': emailAuthentication },
  ...{ 'cbre-pjm-project-tracker-new-project-kahua': projectTrackerNewProjectKahuaLanguage },
  ...{ 'cbre-pjm-project-tracker-adduser': projectTrackerAddUserLanguage },
  ...{ 'cbre-pjm-user-management-search-add-user': userManagementSearchAddUserLanguage },
  ...{ 'cbre-pjm-manage-user': manageUserLanguage },
  ...{ 'cbre-pjm-manage-contact': manageContactLanguage },
  ...{ 'cbre-pjm-create-program': createProgramLanguage },
  ...{ 'cbre-pjm-hierarchy-access-search-add-user-team': HierarchyAccessSearchAddUserTeamLanguage },
  ...{ 'cbre-pjm-manage-teams': ManageTeamsLanguage },
  ...{ 'cbre-pjm-manage-team-users': ManageTeamUsersLanguage },
  ...{ 'cbre-pjm-user-management': userManagementDetailsLanguage },
  ...{ 'cbre-pjm-custom-fields-management': customFieldsManagementDetailsLanguage },
  ...{ 'cbre-pjm-fusion-vendors-projects': fusionVendorProjectsLanguage },
  ...{ 'cbre-pjm-estimator-projects-list': estimatorProjectLanguage },
  ...{ 'cbre-pjm-cpp-form-root': cppFormRoot },
  ...{ 'cbre-pjm-admin-data-import': adminDataImport },
  ...{ 'cbre-pjm-document': documentLanguage },
  ...{ 'cbre-pjm-kahua-data-migration': dataMigrationLanguage },
  ...{ 'cbre-pjm-kahua-new-migration': newDataMigrationLanguage },
  ...{ 'cbre-pjm-capital-plan-moveproject': capitalPlanMoveProjectLanguage },
  ...{ 'cbre-pjm-capital-plan-moveprogram': capitalPlanMoveProgramLanguage },
  ...{ 'cbre-pjm-capital-plan-client-data-fields': clientDataFields },
  ...{ 'cbre-pjm-capital-plan-funding-request': capitalPlanFundingRequest },
  ...{ 'cbre-pjm-pm-projects-list': programManagementProjectLanguage },
  ...{ 'cbre-pjm-pm-program-list': programManagementProgramsList },
  ...{ 'cbre-pjm-project-tagging': projectTagging },
  ...{ 'cbre-pjm-pm-projects-list': programManagementProjectsList },
  ...{ 'cbre-pjm-approvals-status-list': approvalStatusListLanguage },
  ...{ 'cbre-pjm-capital-plan-forecasting': forecast },
  ...{ 'cbre-pjm-principal-lite-project-forecast': forecastPL },
  ...{ 'cbre-pjm-capital-plan-manage-references': capitalPlanManageReference },
  ...{ 'cbre-pjm-manage-list': manageLookupTypeListLanguage },
  ...{ 'cbre-pjm-approval-configuration-add-group': approvalConfigAddGroup },
  ...{ 'cbre-pjm-approvals-group': ApprovalsGroup },
  ...{ 'cbre-pjm-approval-configuration': approvalConfig },
  ...{ 'cbre-pjm-admin-root-details': adminRoot },
  ...{ 'cbre-pjm-principal-lite-project-checklist': checkListRoot },
  ...{ 'cbre-pjm-principal-lite-project-milestones': milestonesRoot },
  ...{ 'cbre-pjm-grid': GridListLanguage },
  ...{ 'cbre-pjm-principal-lite-root': PrincipalLiteRootLanguage },
  ...{ 'cbre-pjm-principal-lite-project-root': PrincipalLiteProjectRootLanguage },
  ...{ 'cbre-pjm-principal-lite-project-basic-information': PrincipalLiteProjectBasicInformationLanguage },
  ...{ 'cbre-pjm-principal-lite-reports': principalLiteReports },
  ...{ 'cbre-pjm-pds-executive-summary': executiveSummaryLanguage },
  ...{ 'cbre-pjm-principal-lite-project-documents': PrincipalLiteProjectDocumentLanguage },
  ...{ 'cbre-pjm-principal-lite-project-cost-plan': principalLiteProjectCostPlan },
  ...{ 'cbre-pjm-principal-lite-new-project': principalLiteNewProject },
  ...{ 'cbre-pjm-principal-lite-moveproject': PrincipalLiteMoveProject },
  ...{ 'cbre-pjm-principal-lite-client-billing': principalLiteClientBilling },
  ...{ 'cbre-pjm-project-cost-overview': ProjectOverviewCost },
  ...{ 'cbre-pjm-approval-history': approvalHistory },
  ...{ 'cbre-pjm-document-repository': DocumentRepository },
  ...{ 'cbre-pjm-principal-lite-project-dashboard': ProjectDashboard },
  ...{ 'cbre-pjm-principal-lite-widget-content': principalLiteRecentProjects },
  ...{ 'cbre-pjm-principal-lite-cpp-document': principalLiteCppDocument },
  ...{ 'cbre-pjm-principal-lite-widget-actions': principalLiteActions },
  ...{ 'cbre-pjm-download': DocumentsDownloadLanguage },
  ...{ 'cbre-pjm-principal-lite-widget-graphs': principalLiteDashboardGraphs },
  ...{ 'cbre-pjm-principal-lite-widget-filters': DashboardFilters },
  ...{ 'cbre-pjm-principal-lite-financial-overview': PrincipalLiteFinancialOverview },
  ...{ 'cbre-pjm-principle-lite-add-user': PrincipalLiteAddUserLanguage },
  ...{ 'cbre-pjm-principal-lite-widget-summary': principalLiteWidgetSummary },
  ...{ 'cbre-pjm-principal-lite-order-book': PrincipalLiteOrderBook },
  ...{ 'cbre-pjm-principal-lite-pipeline': PrincipalLitePipeline },
  ...{ 'cbre-pjm-email-templates': EmailTemplates },
  ...{ 'cbre-pjm-principal-lite-project-budget-management': PrincipalLiteProjectBudgetManagement },
  ...{ 'cbre-pjm-approval-actions': ApprovalActions },
  ...{ 'cbre-pjm-principal-lite-mybuy-requests': MyBuyRequestsPage },
  ...{ 'cbre-pjm-project-template-drawer': ProjectTemplateDrawer },
  ...{ 'cbre-pjm-program-creation-drawer': ProgramCreationDrawer},
  ...{ 'cbre-pjm-principal-lite-programs': MyProjectsProgramsPage },
  ...{ 'cbre-pjm-principal-lite-value-creation': valueCreationLanguage },
});
const EnLang = {
  messages: enLangMessages,
  locale: 'en-US',
};
export default EnLang;
