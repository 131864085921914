export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-value-creation/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-value-creation/SET_LOADING_FALSE';
export const GET_CONTACT_LIST_FAILURE = 'cbre-pjm-principal-value-creation/GET_CONTACT_LIST_FAILURE';
export const GET_CONTACT_LIST_START = 'cbre-pjm-principal-value-creation/GET_CONTACT_LIST_START';
export const GET_CONTACT_LIST_RESET = 'cbre-pjm-principal-value-creation/GET_CONTACT_LIST_RESET';
export const GET_CONTACT_LIST_SUCCESS = 'cbre-pjm-principal-value-creation/GET_CONTACT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAILURE = 'cbre-pjm-principal-value-creation/GET_PROJECT_LIST_FAILURE';
export const GET_PROJECT_LIST_START = 'cbre-pjm-principal-value-creation/GET_PROJECT_LIST_START';
export const GET_PROJECT_LIST_RESET = 'cbre-pjm-principal-value-creation/GET_PROJECT_LIST_RESET';
export const GET_PROJECT_LIST_SUCCESS = 'cbre-pjm-principal-value-creation/GET_PROJECT_LIST_SUCCESS';
export const REFRESH_GRID = 'cbre-pjm-principal-value-creation/REFRESH_GRID';
export const SHOW_POPUP = 'cbre-pjm-principal-value-creation/SHOW_POPUP';
