export const SET_LOADING_TRUE = 'cbre-pjm-program-creation-drawer/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-program-creation-drawer/SET_LOADING_FALSE';
export const GET_TEMPLATE_LIST_FAILURE = 'cbre-pjm-program-creation-drawer/GET_TEMPLATE_LIST_FAILURE';
export const GET_TEMPLATE_LIST_START = 'cbre-pjm-program-creation-drawer/GET_TEMPLATE_LIST_START';
export const GET_TEMPLATE_LIST_RESET = 'cbre-pjm-program-creation-drawer/GET_TEMPLATE_LIST_RESET';
export const GET_TEMPLATE_LIST_SUCCESS = 'cbre-pjm-program-creation-drawer/GET_TEMPLATE_LIST_SUCCESS';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-program-creation-drawer/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-program-creation-drawer/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LEGACY_LOOKUPS_SUCCESS = 'cbre-pjm-program-creation-drawer/GET_ALL_LEGACY_LOOKUPS_SUCCESS';
export const GET_ALL_LEGACY_LOOKUPS_FAILURE = 'cbre-pjm-program-creation-drawer/GET_ALL_LEGACY_LOOKUPS_FAILURE';
export const POST_SAVE_UPDATE_PROGRAM_START = 'cbre-pjm-program-creation-drawer/POST_SAVE_UPDATE_PROGRAM_START';
export const POST_SAVE_UPDATE_PROGRAM_SUCCESS = 'cbre-pjm-program-creation-drawer/POST_SAVE_UPDATE_PROGRAM_SUCCESS';
export const POST_SAVE_UPDATE_PROGRAM_FAILURE = 'cbre-pjm-program-creation-drawer/POST_SAVE_UPDATE_PROGRAM_FAILURE';