/* eslint-disable */
import {
  INCREMENT_PLANSLIST_GRID,
  INCREMENT_CAPITALPLAN_LIST,
  INCREMENT_PROJECTSLIST_GRID,
  INCREMENT_PROJECTTRACKERLIST_GRID,
  INCREMENT_ESTIMATORLIST_GRID,
  INCREMENT_MIGRATION_LIST,
  INCREMENT_PROGRAMSLIST_GRID,
} from 'modules/cbre-pjm-Common/constants/ActionTypes';
import {
  GET_LEGACY_FAILURE,
  GET_LEGACY_SUCCESS,
  GET_ALL_SIMPLE_LOOKUPS_FAILURE,
  GET_ALL_SIMPLE_LOOKUPS_SUCCESS,
} from '../constants/LegacyLookups';
import { STOP_GLOBAL_LOADING, START_GLOBAL_LOADING } from '../constants/Common';
import apiService from 'util/apiService';

export const startGlobalLoading = () => ({
  type: START_GLOBAL_LOADING,
});
export const stopGlobalLoading = () => ({
  type: STOP_GLOBAL_LOADING,
});
export const incrementCapitalPlansListCount = () => {
  return {
    type: INCREMENT_CAPITALPLAN_LIST,
  };
};
export const incrementMigrationListCount = () => {
  return {
    type: INCREMENT_MIGRATION_LIST,
  };
};
export const incrementPlansGridCount = () => {
  return {
    type: INCREMENT_PLANSLIST_GRID,
  };
};
export const incrementProgramsGridCount = () => {
  return {
    type: INCREMENT_PROGRAMSLIST_GRID,
  };
};
export const incrementProjectsGridCount = () => {
  return {
    type: INCREMENT_PROJECTSLIST_GRID,
  };
};
export const incrementProjectTrackerGridCount = () => {
  return {
    type: INCREMENT_PROJECTTRACKERLIST_GRID,
  };
};
export const incrementEstimatorGridCount = () => {
  return {
    type: INCREMENT_ESTIMATORLIST_GRID,
  };
};

export const getAllLegacy = (payload) => {
  const { legacyFilterUrl } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('pjm-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
    },
  };
  return (dispatch) => {
    apiService
      .get(legacyFilterUrl, options)
      .then((res) => {
        const { code, data } = res;
        if (code === 200) {
          dispatch(getAllLegacySuccess(data));
        } else {
          dispatch(getAllLegacyFailure());
        }
      })
      .catch((err) => {
        dispatch(getAllLegacyFailure());
      });
  };
};

export const getAllLegacySuccess = (payload) => ({
  type: GET_LEGACY_SUCCESS,
  payload,
});

export const getAllLegacyFailure = (payload) => ({
  type: GET_LEGACY_FAILURE,
  payload,
});

export const getAllSimpleLookupsSuccess = (payload) => {
  return {
    type: GET_ALL_SIMPLE_LOOKUPS_SUCCESS,
    payload,
  };
};

export const getAllSimpleLookupsFailure = (payload) => ({
  type: GET_ALL_SIMPLE_LOOKUPS_FAILURE,
  payload,
});

export const getAllSimpleLookups = (url) => {
  return async (dispatch) => {
    return apiService
      .get(url)
      .then((responseArr) => {
        const hasData = responseArr.data && responseArr.code === 200;
        if (hasData) {
          const simpleLookups = responseArr.data;
          // console.log('simpleLookups:::',simpleLookups)
          dispatch(getAllSimpleLookupsSuccess(simpleLookups));
        } else {
          dispatch(getAllSimpleLookupsFailure());
        }
      })
      .catch((err) => {
        console.log('Error from getAllSimpleLookups:::', err);
        dispatch(getAllSimpleLookupsFailure());
      });
  };
};
