import { Milestone, GroupedMilestones } from '../components/types';
import moment from 'moment';

// Predefined phases
const predefinedPhases = ['Consult', 'Design', 'Build', 'Activate', 'Closeout'];

export const groupMilestonesByPhase = (milestones: Milestone[]): GroupedMilestones => {
  const enabledMilestones = milestones.filter((milestone) => milestone.enabled);
  const grouped: GroupedMilestones = predefinedPhases.reduce((acc, phase) => {
    acc[phase] = [];
    return acc;
  }, {} as GroupedMilestones);

  if (enabledMilestones.length === 0) {
    return grouped;
  }

  // Iterating over milestones and group them by phase
  enabledMilestones.forEach((milestone) => {
    const phaseName = milestone.phase.phaseName;
    if (grouped[phaseName]) {
      grouped[phaseName].push(milestone);
    }
  });

  //Sorting milestones by sequenceIndex within each phase
  Object.keys(grouped).forEach((phase) => {
    grouped[phase] = grouped[phase]
      .filter((milestone) => milestone.type === 'milestone')
      .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
      .concat(grouped[phase].filter((milestone) => milestone.type === 'subtask'));
  });

  return grouped;
};

export const groupSubtasks = (groupedMilestones: GroupedMilestones): GroupedMilestones => {
  const result: GroupedMilestones = {};
  Object.entries(groupedMilestones).forEach(([phase, milestones]) => {
    if (milestones.length > 0) {
      result[phase] = milestones.filter((milestone) => milestone.type === 'milestone');
      result[phase].forEach((milestone) => {
        const milestoneIdentifier = milestone.id ?? milestone.milestoneTag;
        milestone.subTasks = milestones
          .filter((subtask) => subtask.type === 'subtask' && subtask.parentId === milestoneIdentifier)
          .sort((a, b) => a.sequenceIndex - b.sequenceIndex);
      });
    } else {
      result[phase] = [];
    }
  });
  return result;
};

export const reorderMilestones = (
  milestones: Milestone[],
  draggedMilestone: Milestone,
  droppedMilestone: Milestone,
): Milestone[] => {
  const updatedMilestones = milestones.map((milestone) => {
    const milestoneIdentifier = milestone.id ?? milestone.milestoneTag;
    const draggedIdentifier = draggedMilestone.id ?? draggedMilestone.milestoneTag;
    const droppedIdentifier = droppedMilestone.id ?? droppedMilestone.milestoneTag;

    if (milestoneIdentifier === draggedIdentifier) {
      return { ...milestone, sequenceIndex: droppedMilestone.sequenceIndex };
    }
    if (milestoneIdentifier === droppedIdentifier) {
      return { ...milestone, sequenceIndex: draggedMilestone.sequenceIndex };
    }
    return milestone;
  });
  return updatedMilestones;
};

export const filterMilestones = (milestones: Milestone[], searchTerm: string): Milestone[] => {
  const filteredMilestones = milestones.filter(
    (milestone) =>
      milestone?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      milestone?.assignee?.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      milestone.status.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const parentMilestones = milestones.filter(
    (milestone) =>
      milestone.subTasks?.some(
        (subtask) =>
          subtask?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subtask?.assignee?.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subtask.status.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
  );

  const uniqueMilestonesMap = new Map<string, Milestone>();

  [...filteredMilestones, ...parentMilestones].forEach((milestone) => {
    if (milestone?.id) {
      uniqueMilestonesMap.set(milestone.id, milestone);
    }
  });

  return Array.from(uniqueMilestonesMap.values());
};

export const formatDate = (dateString: string): string => {
  return moment(dateString).format('DD MMM, yyyy');
};

export const convertToISO = (date: any): string =>
  moment.isMoment(date) ? date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : date;

export const convertToLocalISO = (date: any): string => {
  if (moment.isMoment(date)) {
    const localDate = moment(date).local();
    const currentTime = moment().format('HH:mm:ss.SSS');
    console.log('currentTime', localDate.format(`YYYY-MM-DDT${currentTime}[Z]`));
    return localDate.format(`YYYY-MM-DDT${currentTime}[Z]`);
  }
  return date;
};

export const getStatusOptions = (): { [key: string]: string }[] => {
  return [
    { value: 'InProgress', label: 'In Progress' },
    { value: 'Complete', label: 'Complete' },
    { value: 'Pending', label: 'Pending' },
  ];
};

export const getStatusLabel = (value: string): string => {
  const option = getStatusOptions().find((option) => option.value === value);
  return option ? option.label : value;
};

export const getPhases = (): string[] => {
  return ['Consult', 'Design', 'Build', 'Activate', 'Closeout'];
};

export const addSubtask = (milestones: Milestone[], parentId: string, newSubtask: Milestone): Milestone[] => {
  return milestones.map((milestone) => {
    if (milestone.id === parentId) {
      return {
        ...milestone,
        subTasks: [...(milestone.subTasks || []), newSubtask],
      };
    }
    return milestone;
  });
};

export const deleteMilestone = (milestones: Milestone[], milestoneId: string): Milestone[] => {
  return milestones.filter((milestone) => milestone.id !== milestoneId);
};
