import { createSlice } from '@reduxjs/toolkit';

const manageContactInitialState = {
  openDialog: false,
  openAddDialog: false,
  openEditDialog: false,
  contactData: [],
  isLoading: false,
  error: false,
  messageCode: '',
  message: '',
  alert: '',
  alertMessage: '',
  contacts: [],
  refreshData: false,
  isEdit: false,
};

export const manageContactSlice = createSlice({
  name: 'manageContact',
  initialState: manageContactInitialState,
  reducers: {
    openContactDialog: (state, { payload: contactData }) => {
      state.openDialog = true;
      state.contactData = contactData;
      state.isEdit = !!contactData;
      state.refreshData = false;
    },
    closeContactDialog: (state) => {
      state.openDialog = false;
      state.contactData = [];
      state.isEdit = false;
      state.error = false;
      state.messageCode = '';
      state.message = '';
    },
    addNewContactStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
    },
    addNewContactSuccess: (state) => {
      state.isLoading = false;
      state.openDialog = false;
      state.alert = 'success';
      state.alertMessage = 'Contact added successfully';
      state.refreshData = true;
    },
    addNewContactFailure: (state, { payload: { messageCode, message } }) => {
      state.isLoading = false;
      state.error = true;
      state.messageCode = messageCode;
      state.message = message;
    },
    editContactStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
      state.refreshData = false;
    },
    editContactSuccess: (state) => {
      state.isLoading = false;
      state.openDialog = false;
      state.alert = 'success';
      state.alertMessage = 'Contact updated successfully';
      state.refreshData = true;
    },
    editContactFailure: (state, { payload: { messageCode, message } }) => {
      state.isLoading = false;
      state.error = true;
      state.messageCode = messageCode;
      state.message = message;
    },
    closeAlert: (state) => {
      state.alert = '';
      state.alertMessage = '';
    },
    resetManageContact: () => manageContactInitialState,
    resetRefreshData: (state) => {
      state.refreshData = false;
    },
  },
});

export const {
  addNewContactStart,
  addNewContactSuccess,
  addNewContactFailure,
  editContactStart,
  editContactSuccess,
  editContactFailure,
  openContactDialog,
  closeContactDialog,
  closeAlert,
  resetManageContact,
  resetRefreshData,
} = manageContactSlice.actions;
export default manageContactSlice.reducer;
