import { SET_LOADING_TRUE, SET_LOADING_FALSE, REFRESH_GRID, SHOW_POPUP } from './constants';

const INITIAL_STATE = {
  isGridRefreshed: false,
  popupMessage: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case REFRESH_GRID:
      console.log('Reducer: REFRESH_GRID action received');
      return {
        ...state,
        isGridRefreshed: !state.isGridRefreshed,
      };
    case SHOW_POPUP:
      console.log('Reducer: SHOW_POPUP action received');
      return {
        ...state,
        popupMessage: action.payload,
      };
    default:
      return state;
  }
};
