import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_START,
  GET_TEMPLATE_LIST_FAILURE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LEGACY_LOOKUPS_SUCCESS,
  GET_ALL_LEGACY_LOOKUPS_FAILURE,
  POST_SAVE_UPDATE_PROGRAM_SUCCESS,
  POST_SAVE_UPDATE_PROGRAM_START,
  POST_SAVE_UPDATE_PROGRAM_FAILURE,
} from './constants';

const INITIAL_STATE = {
  loading: false,
  templateList: [],
  open: false,
  error: false,
  editedValues: {},
  messageCode: '',
  alert: '',
  alertMessage: '',
  programAlert: false,
  allLegacy: {},
  mode: '',
  editDialogOpen: false,
  client: 0,
  planId: 0,
  status: '',
  fetchLookupsStatus: null,
  fetchAllLegacyLookupStatus: null,
  savedUpdatedProgramData: {message: ''},
  saveUpdateProgramStatus: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_TEMPLATE_LIST_START:
      return {
        ...state,
        loading: true,
        templateList: [],
      };
    case GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case GET_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        templateList: action?.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_ALL_LEGACY_LOOKUPS_SUCCESS: 
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchAllLegacyLookupStatus: 'success',
      }
    case GET_ALL_LEGACY_LOOKUPS_FAILURE: 
      return {
        ...state,
        loading: false,
        fetchAllLegacyLookupStatus: 'error',
      }
    case POST_SAVE_UPDATE_PROGRAM_START:
      return {
        ...state,
        loading: true,
        savedUpdatedProgramData: {
          message: ''
        },
        saveUpdateProgramStatus: 'start',
      }
    case POST_SAVE_UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        savedUpdatedProgramData: action.payload,
        saveUpdateProgramStatus: 'success',
      }
    case POST_SAVE_UPDATE_PROGRAM_FAILURE:
      return {
        ...state,
        loading: false,
        savedUpdatedProgramData: {
          message: action.payload.message
        },
        saveUpdateProgramStatus: 'failed',
      }
    default:
      return state;
  }
};
