export const SET_LOADING_TRUE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_LOADING_FALSE';
export const OPEN_DELETE_PROJECT_DIALOG = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/OPEN_DELETE_PROJECT_DIALOG';
export const OPEN_DELETE_PROJECT_DIALOG_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/OPEN_DELETE_PROJECT_DIALOG_SUCCESS';
export const OPEN_DELETE_PROJECT_DIALOG_FAILURE =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/OPEN_DELETE_PROJECT_DIALOG_FAILURE';
export const CLOSE_DELETE_PROJECT_DIALOG = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/CLOSE_DELETE_PROJECT_DIALOG';
export const CLOSE_DELETE_PROJECT_TOAST = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/CLOSE_DELETE_PROJECT_TOAST';

export const DELETE_PROJECT_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/DELETE_PROJECT_FAILURE';

export const SET_USER_AS_LEADER = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_USER_AS_LEADER';
export const SET_USER_AS_NON_LEADER = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_USER_AS_NON_LEADER';

export const MOVE_PROJECTS_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/MOVE_PROJECTS_START'; // MOVE_PROJECTS
export const MOVE_PROJECTS_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/MOVE_PROJECTS_SUCCESS';
export const MOVE_PROJECTS_FAILURE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/MOVE_PROJECTS_FAILURE';
