export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-project-milestones/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-project-milestones/SET_LOADING_FALSE';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-principal-lite-project-milestones/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-principal-lite-project-milestones/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LOOKUPS_START = 'cbre-pjm-principal-lite-project-milestones/GET_ALL_LOOKUPS_START';
export const GET_MILESTONES_UNDER_PROJECT_START = 'cbre-pjm-principal-lite-project-milestones/GET_MILESTONES_UNDER_PROJECT_START';
export const GET_MILESTONES_UNDER_PROJECT_SUCCESS =
  'cbre-pjm-principal-lite-project-milestones/GET_MILESTONES_UNDER_PROJECT_SUCCESS';
export const GET_MILESTONES_UNDER_PROJECT_FAILURE =
  'cbre-pjm-principal-lite-project-milestones/GET_MILESTONES_UNDER_PROJECT_FAILURE';
export const RESET_MILESTONES_UNDER_PROJECT =
  'cbre-pjm-principal-lite-project-milestones/RESET_MILESTONES_UNDER_PROJECT';
export const ADD_MILESTONE = 'cbre-pjm-principal-lite-project-milestones/ADD_MILESTONE';
export const REORDER_MILESTONES = 'cbre-pjm-principal-lite-project-milestones/REORDER_MILESTONES';
export const DELETE_MILESTONE = 'cbre-pjm-principal-lite-project-milestones/DELETE_MILESTONE';
export const DELETE_UNSAVED_MILESTONE = 'cbre-pjm-principal-lite-project-milestones/DELETE_UNSAVED_MILESTONE';
export const SEARCH_MILESTONES = 'cbre-pjm-principal-lite-project-milestones/SEARCH_MILESTONES';
export const CREATE_MILESTONES_FAILURE = 'cbre-pjm-principal-lite-project-milestones/CREATE_MILESTONES_FAILURE';
export const UPDATE_MILESTONES_FAILURE = 'cbre-pjm-principal-lite-project-milestones/UPDATE_MILESTONES_FAILURE';
export const SET_TOASTER_MESSAGE = 'cbre-pjm-principal-lite-project-milestones/SET_TOASTER_MESSAGE';
export const CLEAR_TOASTER_MESSAGE = 'cbre-pjm-principal-lite-project-milestones/CLEAR_TOASTER_MESSAGE';
export const MODULE = 'cbre-pjm-principal-lite-project-milestones';
